.container {
  position: relative;
  padding: 40px 60px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(167, 183, 219, 0.5);
  .title {
    color: #030a1a;
    font-weight: 700;
    font-size: 28px;
    text-align: center;
  }
  .titleStrong {
    color: #1a66ff;
  }
  .desc {
    margin: 0 0 28px;
    color: #757a85;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  .btn {
    width: 100%;
    height: 40px;
    margin-top: 8px;
  }
  :global {
    .ant-form-item {
      margin-bottom: 20px;
    }
  }
}

.modal {
  :global {
    .ant-modal-confirm-btns {
      display: none !important;
    }
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-body .ant-modal-confirm-content {
      margin-top: 0 !important;
    }
  }
}
