.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  padding-top: 72px;
  border-radius: 8px;
  box-shadow: 0 44px 88px 0 #030a1a33;
  .title {
    font-weight: 700;
    font-size: 24px;
    white-space: nowrap;
  }
  .titleStrong {
    margin: 0 4px;
    color: #1a66ff;
  }
  .desc {
    margin: 0 0 16px;
    color: #757a85;
    font-weight: 400;
    font-size: 14px;
  }

  .form {
    max-height: calc(100% - 72px - 88px);
    overflow-y: auto;
  }
  .moreTips {
    margin-top: 16px;
    text-align: center;
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px;
    column-gap: 32px;
    background-color: #fff;
    box-shadow: 0 0 30px 0 #0f131a33;
    .closeText {
      color: #757a85;
      font-size: 14px;
      text-decoration: underline;
    }
    .btn {
      width: 100%;
      height: 40px;
      border-radius: 32px;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 14px;
    }
  }
}

.cascaderDropdown {
  max-width: calc(100% - 40px);
  :global {
    .ant-cascader-menu-item-content {
      max-width: 100%;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
