.container {
  position: relative;
  width: 610px;
  margin-top: 56px;
  padding: 40px 60px;
  border-radius: 8px;
  box-shadow: 0 44px 88px 0 #030a1a33;
  .close {
    position: absolute;
    top: 8px;
    right: 16px;
    color: #999;
    font-size: 12px;
    cursor: pointer;
  }
  .title {
    font-weight: 700;
    font-size: 28px;
    white-space: nowrap;
  }
  .titleStrong {
    margin: 0 4px;
    color: #1a66ff;
  }
  .desc {
    margin: 0 0 28px;
    color: #757a85;
    font-weight: 400;
    font-size: 14px;
  }
  .btn {
    width: 100%;
    height: 40px;
    margin-top: 8px;
  }
  :global {
    .ant-form-item {
      margin-bottom: 20px;
    }
  }
}

.modal {
  :global {
    .ant-modal-confirm-btns {
      display: none !important;
    }
    .ant-modal-body {
      padding: 0 !important;
    }
    .ant-modal-body .ant-modal-confirm-content {
      margin-top: 0 !important;
      color: #030a1a !important;
    }
    #container {
      margin-top: 0;
    }
    #title,
    #desc {
      text-align: center;
    }
  }
}
