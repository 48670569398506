.submit-btn-style {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 14px;
  background: #1A66FF;
  border-radius: 4px;
}
.submit-btn-deActive{
  opacity: 0.6;
}
.submit-btn-deActive:hover{
  background: #1A66FF;
}
.submit-btn-deActive:active{
  background: #1A66FF;
}
.submit-btn-deActive:focus{
  background: #1A66FF;
}