.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 #0000001A;

  & > * {
    flex-shrink: 0;
  }

  .title {
    margin-top: 4px;
    margin-left: 12px;
  }
}
