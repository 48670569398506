.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 26px;
  .title {
    color: #030a1a;
    font-weight: 700;
    font-size: 24px;
  }
  .titleStrong {
    color: #1a66ff;
  }
  .desc {
    margin: 0 0 40px;
    color: #757a85;
    font-weight: 400;
    font-size: 14px;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    row-gap: 24px;
    .qrcodeArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 170px;
      height: 200px;
      background-color: #f2f5fa;
      .qrcodeText1 {
        color: #757a85;
        font-size: 16px;
        line-height: 18px;
      }
      .qrcodeText2 {
        color: #1a66ff;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
      }
    }
    .giftArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 20px;
      .giftItem {
        flex-wrap: nowrap;
        width: 312px;
        height: 40px;
        border: 1px solid #1a66ff;
        box-sizing: content-box;
      }
      .giftLabel {
        width: 96px;
        padding: 8px;
        color: #030a1a;
        font-weight: 500;
        font-size: 15px;
        white-space: nowrap;
        border-right: 1px solid #1a66ff;
        background-color: #E9F0FF;
      }
      .giftContent {
        // width: 225px;
        padding: 8px;
        color: #030a1a;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
      }
      .giftTextStrong {
        margin-left: 4px;
        color: #1a66ff;
        font-weight: 700;
        font-size: 15px;
      }
    }
  }
  .footer {
    margin-top: auto;
    column-gap: 88px;
    .closeText {
      color: #757a85;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}
